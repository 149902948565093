<template lang="pug">
.tinyMCE-editor
  Editor(
    ref="wrapper"
    api-key="22e9tjvd2j0094f1oixtdl2asqzn78n9vryrl3wxr1xnv54r"
    :init="initValues"
    :value="value"
  )
</template>

<script>
  import Editor from '@tinymce/tinymce-vue';
  import { restClient as axios } from '@/axios';

  const initProps = {
    plugins:
      'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
    schema: 'html5',
    height: 300,
    valid_elements: '*[*]',
    automatic_uploads: true,
    init_instance_callback(editor) {
      window.om.bus.$emit('tinymce-inited', editor);
      const emitContent = () => {
        window.om.bus.$emit('changeContent', editor.getContent());
      };

      editor.on('Change', emitContent);
      editor.on('blur', emitContent);
      editor.on('focus', emitContent);
    },
    async images_upload_handler(blobInfo, success, failure) {
      const data = new FormData();
      data.append('file', blobInfo.blob(), blobInfo.filename());

      try {
        const resp = await axios.post('upload/template-thumbnails', data, {
          headers: { 'content-type': 'multipart/form-data' },
        });

        if (resp.status === 200 && resp.data && resp.data.url) {
          success(resp.data.url);
        }
        throw new Error('Image url is missing from response');
      } catch (err) {
        const message =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : err.message;
        failure(message, { remove: true });
      }
    },
  };

  const _clone = (v) => JSON.parse(JSON.stringify(v));

  export default {
    components: { Editor },
    props: ['value', 'height'],
    data: () => ({ internalValue: null }),
    computed: {
      initValues() {
        return {
          ...initProps,
          height: this.height || initProps.height,
        };
      },
    },
    created() {
      this.internalValue = _clone(this.value || '');
    },
    mounted() {
      window.om.bus.$on('changeContent', this.update);
    },
    beforeDestroy() {
      window.om.bus.$off('changeContent', this.update);
    },
    methods: {
      update() {
        this.$emit('input', this.$refs.wrapper.editor.getContent());
      },
    },
  };
</script>

<style lang="sass">
  .tox.tox-silver-sink.tox-tinymce-aux
    z-index: 99999999
</style>
