<template lang="pug">
om-modal(
  name="new-use-case"
  modalClasses="brand-modal-size-wider"
  @beforeOpen="onBeforeOpen"
  @beforeClose="onBeforeClose"
  :clickToClose="false"
)
  template(#modal-header)
    .row
      .col
        .title {{ $t(`useCaseManager.titles.${mode}`) }}
    .brand-modal-action-icon
      span(class="cursor-pointer" @click="$modal.hide('new-use-case')" title="close" aria-hidden="true")
        close-icon(:width="12" :height="12")
  template(#modal-body)
    div(v-if="$apollo.loading") Loading...
    template(v-else)
      .mb-4
        .form-group.row.align-items-center
          .col-sm-12.col-form-label
            label.mb-0(for="svgPreview") {{ $t('useCaseManager.labels.svgPreview') }}
          .col-sm-12
            .img-preview.img-preview-logo.mb-4(v-if="svgPreviewSrc")
              img.cursor-pointer(:src="svgPreviewSrc" @click.prevent="$refs.svgPreview.click()" style="max-width:450px;height:auto;object-fit:contain")
            input(ref="svgPreview" type="file" style='display: none' accept="image/jpeg|image/png|image/gif|text/svg" @change="handleFileSelect")
            button.btn.btn-secondary(@click.prevent="$refs.svgPreview.click()") {{ $t('browse') }}
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-sm-3.col-form-label
            label.mb-0(for="goals") {{ $t('goalsLabel') }}
              i.fa.fa-info-circle.mx-2(v-tooltip="{ content: $t('templateManager.tooltips.goals') }" style="position:relative;color:#ababab")
          .col-sm-9
            multiselect(id="goals"
              :options="tacticGoalsOpts"
              label="key"
              track-by="value"
              :placeholder="$t('templateManager.placeholders.goals')"
              :multiple="true"
              :show-labels="false"
              :allow-empty="true"
              v-model="settings.goals"
              open-direction="bottom")
              template(slot="selection" slot-scope="{ values, search, isOpen }")
                span.multiselect__single(v-if="values.length && !isOpen")
                  strong {{ values[0].key }}
                  | {{ (values.length - 1) ? ` + ${values.length - 1} ${$t('more')}` : '' }}
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-sm-3.col-form-label
            label.mb-0(for="industries") {{ $t('useCaseManager.labels.industries') }}
              i.fa.fa-info-circle.mx-2(v-tooltip="{ content: $t('useCaseManager.tooltips.industries') }" style="position:relative;color:#ababab")
          .col-sm-9
            multiselect(id="industries"
              :options="tacticIndustryOpts"
              label="key"
              track-by="value"
              :placeholder="$t('useCaseManager.placeholders.industries')"
              :multiple="true"
              :show-labels="false"
              :allow-empty="true"
              v-model="settings.industries"
              open-direction="bottom")
              template(slot="selection" slot-scope="{ values, search, isOpen }")
                span.multiselect__single(v-if="values.length && !isOpen")
                  strong {{ values[0].key }}
                  | {{ (values.length - 1) ? ` + ${values.length - 1} ${$t('more')}` : '' }}
        .row.mt-2
          .col-sm-3
          .col-sm-9
            .text-danger.text-left.font-size-0--8125(v-if="$v.settings.industries.$error") {{ $t('fieldRequired') }}
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-sm-3.col-form-label
            label.mb-0(for="difficulty") {{ $t('useCaseManager.labels.difficulty') }}
          .col-sm-9
            multiselect(id="difficulty"
              :options="difficultyOpts"
              label="key"
              track-by="value"
              :placeholder="$t('useCaseManager.placeholders.difficulty')"
              :multiple="false"
              :show-labels="false"
              :allow-empty="true"
              v-model="difficulty"
              open-direction="bottom")
        .row.mt-2
          .col-sm-3
          .col-sm-9
            .text-danger.text-left.font-size-0--8125(v-if="$v.settings.difficulty.$error") {{ $t('fieldRequired') }}
      .mb-4
        .form-group.row.align-items-center
          .col-sm-3.col-form-label
            label.mb-0(for="value3") Score
          .col-sm-9
            input.form-control.form-control-md(id="value3" type='number' placeholder="Score" v-model.number="settings.value3")
      .mb-4
        .form-group.row.align-items-center
          .col-sm-3.col-form-label
            label.mb-0(for="video") Video
          .col-sm-9
            input.form-control.form-control-md(id="video" type='url' placeholder="Video" v-model="settings.video")
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-6
            .row
              .col-sm-12
                label.mb-0(for="name-en") {{ $t('name') }} (EN)
              .col-sm-12
                input.form-control.form-control-md(id="name-en" type='text' :placeholder="$t('name')" v-model="settings.name.en")
              .col-12
                .text-danger.text-left.font-size-0--8125(v-if="$v.settings.name.en.$error") {{ $t('fieldRequired') }}
          .col-6
            .row
              .col-sm-12
                label.mb-0(for="name-hu") {{ $t('name') }} (HU)
              .col-sm-12
                input.form-control.form-control-md(id="name-hu" type='text' :placeholder="$t('name')" v-model="settings.name.hu")
              .col-12
                .text-danger.text-left.font-size-0--8125(v-if="$v.settings.name.hu.$error") {{ $t('fieldRequired') }}
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-6
            .row
              .col-sm-12.mb-1
                label.mb-0(for="subHeadline") Headline (EN)
              .col-sm-12.mb-1
                input.form-control.form-control-md(id="subHeadline" type='text' placeholder="Headline (EN)" v-model="settings.subHeadline")
          .col-6
            .row
              .col-sm-12.mb-1
                label.mb-0(for="segmentHu") Headline (HU)
              .col-sm-12.mb-1
                input.form-control.form-control-md(id="segmentHu" type='text' placeholder="Headline (HU)" v-model="settings.segment.hu")
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-6
            .row
              .col-sm-12.col-form-label
                label.mb-0(for="whyShould1") Subheadline (EN)
              .col-sm-12
                input.form-control.form-control-md(type="text" v-model="settings.usage.en")
              .col-sm-12
                .text-danger.text-left.font-size-0--8125(v-if="$v.settings.usage.en.$error") {{ $t('fieldRequired') }}
          .col-6
            .row
              .col-sm-12
                .row
                  .col-sm-12.col-form-label
                    label.mb-0(for="whyShould2") Subheadline (HU)
                  .col-sm-12
                    input.form-control.form-control-md(type="text" v-model="settings.usage.hu")
                  .col-sm-12
                    .text-danger.text-left.font-size-0--8125(v-if="$v.settings.usage.hu.$error") {{ $t('fieldRequired') }}
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-6
            .row
              .col-sm-12.mb-1
                label.mb-0(for="value1") Expected result 1 (EN)
              .col-sm-12.mb-1
                input.form-control.form-control-md(id="value1" type='text' placeholder="Expected result 1" v-model="settings.value1")
          .col-6
            .row
              .col-sm-12.mb-1
                label.mb-0(for="value1") Expected result 1 (HU)
              .col-sm-12.mb-1
                input.form-control.form-control-md(id="value1" type='text' placeholder="Expected result 1" v-model="settings.value1Hu")

      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-6
            .row
              .col-sm-12.mb-1
                label.mb-0(for="value2") Expected result 2 (EN)
              .col-sm-12.mb-1
                input.form-control.form-control-md(id="value2" type='text' placeholder="Expected result 2" v-model="settings.value2")

          .col-6
            .row
              .col-sm-12.mb-1
                label.mb-0(for="value2") Expected result 2 (HU)
              .col-sm-12.mb-1
                input.form-control.form-control-md(id="value2" type='text' placeholder="Expected result 2" v-model="settings.value2Hu")

      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-6
            .row
              .col-sm-12.mb-1
                label.mb-0(for="tags1") Tags (EN)
              .col-sm-12.mb-1
                input.form-control.form-control-md(id="tags1" type='text' placeholder="Tags" v-model="settings.segment.en")

          .col-6
            .row
              .col-sm-12.mb-1
                label.mb-0(for="tags2") Tags (HU)
              .col-sm-12.mb-1
                input.form-control.form-control-md(id="tags2" type='text' placeholder="Tags" v-model="settings.tagsHu")
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-6
            .row
              .col-sm-12.mb-1
                label.mb-0(for="search_tags1") Search Tags (EN)
              .col-sm-12.mb-1
                om-tags.form-control.form-control-md(
                  id="search_tags1"
                  trackBy="name"
                  v-model="settings.searchTags.en"
                )

          .col-6
            .row
              .col-sm-12.mb-1
                label.mb-0(for="search_tags2") Search  Tags (HU)
              .col-sm-12.mb-1
                om-tags.form-control.form-control-md(
                  id="search_tags1"
                  trackBy="name"
                  v-model="settings.searchTags.hu"
                )
        .mb-4
          .form-group.row.align-items-center
            .col-6
              .row
                .col-sm-12.col-form-label
                  label.mb-0(for="targeting1") When to use it? (EN)
                    //- i.fa.fa-info-circle.mx-2(v-tooltip="{ content: $t('useCaseManager.tooltips.targeting') }" style="position:relative;color:#ababab")
                .col-sm-12
                  TinyMCEEditor(v-model="settings.targeting.en")
                .col-sm-12
                  .text-danger.text-left.font-size-0--8125(v-if="$v.settings.targeting.en.$error") {{ $t('fieldRequired') }}
            .col-6
              .row
                .col-sm-12.col-form-label
                  label.mb-0(for="targeting2") When to use it? (HU)
                    //- i.fa.fa-info-circle.mx-2(v-tooltip="{ content: $t('useCaseManager.tooltips.targeting') }" style="position:relative;color:#ababab")
                .col-sm-12
                  TinyMCEEditor(v-model="settings.targeting.hu")
                .col-sm-12
                  .text-danger.text-left.font-size-0--8125(v-if="$v.settings.targeting.hu.$error") {{ $t('fieldRequired') }}

      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-sm-3.col-form-label
            label.mb-0(for="templates") {{ $t('templates') }}
              i.fa.fa-info-circle.mx-2(v-tooltip="{ content: $t('useCaseManager.tooltips.templates') }" style="position:relative;color:#ababab")
          .col-sm-9
            multiselect(id="templates"
              :options="mappedTemplates"
              label="key"
              track-by="value"
              :placeholder="$t('useCaseManager.placeholders.templates')"
              :multiple="true"
              :show-labels="false"
              :allow-empty="true"
              v-model="settings.templates"
              open-direction="bottom")
              template(slot="option" slot-scope="{ option }")
                .multiselect-option-with-image
                  img.option__image(:src="option.previews[0]" :alt="option.key" width="40")
                  .option__desc
                    span.option__title {{ option.key }}
              template(slot="selection" slot-scope="{ values, search, isOpen }")
                span.multiselect__single(v-if="values.length && !isOpen")
                  strong {{ values[0].key }}
                  | {{ (values.length - 1) ? ` + ${values.length - 1} ${$t('more')}` : '' }}
          .col-12
            om-chips.mr-2.flex-wrap(
              :items="settings.templates"
              removable
              contentKey="key"
              :style="'margin-top: -5px'"
            )
        .row.mt-2
          .col-sm-3
          .col-sm-9
            .text-danger.text-left.font-size-0--8125(v-if="$v.settings.templates.$error") {{ $t('fieldRequired') }}
      .form-group.row.align-items-center.mb-4
        .col-sm-12
          .row
            .col-sm-12.col-form-label
              label.mb-0 How to set it up? (EN)
            .col-sm-12
              TinyMCEEditor(v-model="settings.customHTML" height="600")
      .form-group.row.align-items-center.mb-4
        .col-sm-12
          .row
            .col-sm-12.col-form-label
              label.mb-0 How to set it up? (HU)
            .col-sm-12
              TinyMCEEditor(v-model="settings.customHTMLhu" height="600")

  template(slot="modal-footer")
    .d-flex.justify-content-center(v-if="!$apollo.loading")
      template(v-if="isNew")
        om-button(primary @click="createOrSave(true)") {{ $t('create') }}
      template(v-else)
        om-button.mr-3(primary @click="createOrSave(false)") {{ $t('save') }}
        om-button(primary @click="createOrSave(true)") {{ $t('editSettings') }}
</template>

<script>
  import { required, requiredIf } from 'vuelidate/lib/validators';
  import GET_TEMPLATES from '@/graphql/TemplatesForUseCaseManager.gql';
  import UPSERT_USE_CASE from '@/graphql/UseCaseUpsert.gql';
  import GET_USE_CASE from '@/graphql/GetUseCase.gql';
  import { get as _get } from 'lodash-es';
  import fileUploadMixin from '@/mixins/file-upload';
  import tacticSelectOptionsMixin from '@/mixins/tactic/selectOptions';
  import TinyMCEEditor from '@/components/Tactic/TinyMCE.vue';

  const baseSettings = {
    name: {
      hu: '',
      en: '',
    },
    goals: [],
    tactics: [],
    industries: [],
    templates: [],
    thumbnail: null,
    svgPreviews: [],
    frontendRules: [],
    events: [],
    difficulty: null,
    ojoStages: [],
    targeting: {
      en: null,
      hu: null,
    },
    usage: {
      en: null,
      hu: null,
    },
    mainTemplate: null,
    subHeadline: null,
    value1: null,
    value1Hu: null,
    value2: null,
    value2Hu: null,
    value3: null,
    segment: {
      en: null,
      hu: null,
    },
    searchTags: {
      en: [],
      hu: [],
    },
    content: null,
    video: null,
    customHTML: null,
    customHTMLhu: null,
    tags2Hu: null,
  };

  export default {
    components: { TinyMCEEditor },
    mixins: [fileUploadMixin, tacticSelectOptionsMixin],
    props: {
      goals: { type: Array, default: () => [] },
      tactics: { type: Array, default: () => [] },
      industries: { type: Array, default: () => [] },
    },
    validations: {
      settings: {
        name: {
          hu: { required },
          en: { required },
        },
        industries: { required },
        targeting: {
          hu: { required },
          en: { required },
        },
        usage: {
          hu: { required },
          en: { required },
        },
        difficulty: { required },
        templates: {
          required: requiredIf(function (state) {
            return state.difficulty === 'low';
          }),
        },
        // thumbnail: { required },
      },
    },
    data: () => ({
      templates: [],
      settings: {
        ...baseSettings,
        name: { en: '', hu: '' },
      },
      mode: 'new',
      id: null,
      svgPreviewFile: null,
      svgPreviewSrc: null,
      useCase: null,
    }),
    computed: {
      mappedTemplates() {
        return this.templates.map((tpl) => {
          return {
            key: `(${(tpl.locale || '').toUpperCase()}) ${tpl._id}, ${tpl.displayName} (${
              tpl.name
            })`,
            value: tpl._id,
            displayName: `(${(tpl.locale || '').toUpperCase()}) ${tpl.displayName} (${tpl.name})`,
            previews: tpl.previewUrls,
          };
        });
      },
      selectedTemplateIds() {
        return this.settings.templates.map((t) => t.value.toString());
      },
      selectedTemplates() {
        return this.mappedTemplates.filter((t) =>
          this.selectedTemplateIds.includes(t.value.toString()),
        );
      },
      realSettings() {
        return {
          ...this.settings,
          goals: this.settings.goals.map((item) => item.value),
          industries: this.settings.industries.map((item) => item.value),
          tactics: this.settings.tactics.map((item) => item.value),
          templates: this.settings.templates.map(({ value }) => value),
          thumbnail: _get(this.settings, 'thumbnail.value', null),
          mainTemplate: _get(this.settings, 'mainTemplate.value', null),
        };
      },
      isNew() {
        return this.mode === 'new';
      },
      ojoStagesOpts() {
        const stages = this.$i18n.messages[this.$i18n.locale].useCaseManager.ojoStages;

        return Object.entries(stages).map(([value, key]) => ({ value, key }));
      },
      ojoStages: {
        get() {
          return (this.settings.ojoStages || []).map((setValue) => {
            return this.ojoStagesOpts.find(({ value }) => value === setValue);
          });
        },
        set(items) {
          this.settings.ojoStages = items.map(({ value }) => value);
        },
      },
      difficultyOpts() {
        const difficulties = this.$i18n.messages[this.$i18n.locale].useCaseManager.difficulty;

        return Object.entries(difficulties).map(([value, key]) => ({ value, key }));
      },
      difficulty: {
        get() {
          return this.difficultyOpts.find(({ value }) => value === this.settings.difficulty);
        },
        set(item) {
          this.settings.difficulty = item ? item.value : null;
        },
      },
    },
    watch: {
      'settings.name.en': function (n, o) {
        if (!this.settings.name.hu || this.settings.name.hu === o) {
          this.settings.name.hu = n;
        }
      },
      'settings.targeting.en': function (n, o) {
        if (!this.settings.targeting.hu || this.settings.targeting.hu === o) {
          this.settings.targeting.hu = n;
        }
      },
      settings: {
        handler() {
          this.$v.$reset();
        },
        deep: true,
        immediate: true,
      },
    },
    apollo: {
      getTemplates: {
        query: GET_TEMPLATES,
        manual: true,
        variables() {
          return {
            locale: this.$i18n.locale,
          };
        },
        result({ data, loading }) {
          if (!loading) {
            this.templates = data.templates;
          }
        },
      },
      useCase: {
        query: GET_USE_CASE,
        variables() {
          return { id: this.id };
        },
        skip() {
          return !this.id;
        },
      },
    },
    methods: {
      convertSearchTags() {
        this.settings.searchTags.en = this.settings.searchTags.en.map((tag) => tag.name);
        this.settings.searchTags.hu = this.settings.searchTags.hu.map((tag) => tag.name);
      },
      searchTagsMapper(searchTags) {
        this.settings.searchTags.en =
          searchTags?.en.map((tag) => ({
            name: tag,
          })) || [];
        this.settings.searchTags.hu =
          searchTags?.hu.map((tag) => ({
            name: tag,
          })) || [];
      },
      async onBeforeOpen(event) {
        this.useCase = null;
        this.id = null;

        if (event.params) {
          const { mode, data: { _id = null } = { _id: null } } = event.params;
          this.mode = mode || 'new';
          this.id = _id;
        }

        await Promise.all([
          this.$apollo.queries.getTemplates.refetch(),
          this.$apollo.queries.useCase.refetch(),
        ]);

        this.svgPreviewFile = null;
        this.svgPreviewSrc = null;
        this.settings = {
          ...baseSettings,
          name: { en: '', hu: '' },
          targeting: { en: '', hu: '' },
          svgPreviews: [],
          customHTML: '',
          customHTMLhu: '',
          segment: { en: '', hu: '' },
          searchTags: { en: [], hu: [] },
        };

        if (!this.isNew) {
          const {
            name,
            goals,
            industries,
            tactics,
            templates,
            thumbnail,
            frontendRules,
            events,
            targeting = {
              en: null,
              hu: null,
            },
            usage = {
              en: null,
              hu: null,
            },
            svgPreviews = [],
            difficulty = null,
            ojoStages = null,
            mainTemplate,
            subHeadline,
            value1,
            value2,
            value3,
            segment,
            searchTags,
            content,
            video,
          } = event?.params?.data || {};

          this.settings.name.en = name.en;
          this.settings.name.hu = name.hu;
          this.settings.svgPreviews = svgPreviews;
          this.settings.ojoStages = ojoStages;
          this.settings.difficulty = difficulty;
          this.svgPreviewSrc = svgPreviews[0] || null;
          this.settings.goals = goals
            .map((item) => {
              return this.tacticGoalsOpts.find(({ value }) => value === item);
            })
            .filter((item) => !!item);
          this.settings.industries = industries
            .map((item) => {
              return this.tacticIndustryOpts.find(({ value }) => value === item);
            })
            .filter((item) => !!item);
          this.settings.tactics = tactics
            .map((item) => {
              return this.tactics.find(({ value }) => value === item);
            })
            .filter((item) => !!item);
          this.settings.templates = templates
            .map((id) => {
              return this.mappedTemplates.find(({ value }) => value === id);
            })
            .filter((item) => !!item);
          this.settings.thumbnail = this.mappedTemplates.find(({ value }) => value === thumbnail);
          this.settings.frontendRules = frontendRules;
          this.settings.events = events;
          this.settings.targeting = targeting;
          this.settings.usage = usage;
          this.settings.mainTemplate = this.mappedTemplates.find(
            ({ value }) => value === mainTemplate,
          );
          this.settings.subHeadline = subHeadline;
          this.settings.value1 = value1;
          this.settings.value2 = value2;
          this.settings.value3 = value3;
          this.settings.content = content;
          this.settings.video = video;

          if (typeof segment === 'string' || Array.isArray(segment)) {
            // Array.isArray(segment): its a string, but observer added
            this.settings.segment = {
              en: segment,
              hu: '',
            };
          } else {
            this.settings.segment.en = segment.en || '';
            this.settings.segment.hu = segment.hu || '';
          }

          this.searchTagsMapper(searchTags);
        }
        this.settings.customHTML = this.useCase?.customHTML || '';
        this.settings.customHTMLhu = this.useCase?.customHTMLhu || '';
        this.settings.value1Hu = this.useCase?.value1Hu || '';
        this.settings.value2Hu = this.useCase?.value2Hu || '';
        this.settings.tagsHu = this.useCase?.tagsHu || '';
        this.$v.$reset();
      },
      onBeforeClose() {
        this.mode = 'new';
        this.id = null;
        this.settings = {
          ...baseSettings,
          name: { en: '', hu: '' },
          targeting: { en: '', hu: '' },
          segment: { en: '', hu: '' },
          searchTags: { en: [], hu: [] },
          svgPreviews: [],
        };
        this.$v.$reset();
      },
      async createOrSave(openRulesEdit) {
        this.$v.$touch();

        if (this.$v.$error) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
          return;
        }

        if (this.svgPreviewFile) {
          const url = await this.uploadFile(this.svgPreviewFile, 'upload/template-thumbnails');
          this.settings.svgPreviews = [url];
        }

        this.convertSearchTags();

        const variables = { settings: this.realSettings };
        if (this.id) {
          variables.id = this.id;
        }
        const { data } = await this.$apollo.mutate({
          mutation: UPSERT_USE_CASE,
          variables,
        });
        const res = data && data.result;

        if (res && (res._id || res.ok)) {
          if (!this.isNew) {
            this.$bus.$emit('updateUseCase', variables);
            this.$bus.$emit('fetchUseCases');
          }

          this.$modal.hide('new-use-case');

          const isOpenRulesEdit = this.isNew || openRulesEdit;
          if (isOpenRulesEdit && !!this.settings?.templates?.length) {
            this.$router.push({ name: 'use_case_rules', params: { id: res._id || this.id } });
          }
        }
      },

      handleFileSelect() {
        const input = this.$refs.svgPreview;

        if (input.files.length > 0) {
          this.svgPreviewFile = input.files[0];

          const reader = new FileReader();
          reader.onload = (e) => {
            this.svgPreviewSrc = e.target.result;
          };
          reader.readAsDataURL(this.svgPreviewFile);

          input.value = null;
        }
      },
    },
  };
</script>

<style lang="sass">
  .col-sm-12
    .brand-tags-holder
      min-height: 28px
      padding: 3px 0px 0px 2px
  .multiselect-option-with-image
    display: flex
    align-items: center
    height: 3.75rem
    .option__title
      margin-left: 1rem
</style>
