<template lang="pug">
om-modal(
  name="segments-superadmin-modal"
  @beforeOpen="onBeforeOpen"
  @beforeClose="onBeforeClose"
  width="80%"
)
  template(#modal-header)
    .row
      .col
        .title {{ title }}
    .brand-modal-action-icon
      span.cursor-pointer(
        :disabled="saving"
        @click="$modal.hide('segments-superadmin-modal')"
        title="close"
        aria-hidden="true"
      )
        close-icon(:width="12" :height="12")
  template(#modal-body)
    .mb-4
      .form-group.row.align-items-center.mb-0
        .col-sm-3.col-form-label
          label.mb-0(for="segment-name-en") Name (EN)
        .col-sm-9
          input#segment-name-en.form-control.form-control-md(
            type="text"
            placeholder="Name (EN)"
            v-model="settings.name.en"
          )
      .row.mt-2
        .col-sm-3
        .col-sm-9
          .text-danger.text-left.font-size-0--8125(v-if="$v.settings.name.en.$error") {{ $t('fieldRequired') }}
    .mb-4
      .form-group.row.align-items-center.mb-0
        .col-sm-3.col-form-label
          label.mb-0(for="segment-name-hu") Name (HU)
        .col-sm-9
          input#segment-name-hu.form-control.form-control-md(
            type="text"
            placeholder="Name (HU)"
            v-model="settings.name.hu"
          )
      .row.mt-2
        .col-sm-3
        .col-sm-9
          .text-danger.text-left.font-size-0--8125(v-if="$v.settings.name.hu.$error") {{ $t('fieldRequired') }}
    .mb-4
      .form-group.row.align-items-center.mb-0
        .col-sm-3.col-form-label
          label.mb-0(for="segment-show-for") Show for
        .col-sm-9
          om-select#segment-show-for(
            v-model="settings.showFor"
            :options="showForOptions"
            :placeholder="$t('select')"
          )
      .row.mt-2
        .col-sm-3
        .col-sm-9
          .text-danger.text-left.font-size-0--8125(v-if="$v.settings.name.hu.$error") {{ $t('fieldRequired') }}
    .mb-4
      .form-group.row.align-items-center
        .col-sm-3.col-form-label
          label.mb-0(for="icon") {{ $t('icon') }}
        .col-sm-9
          input#icon(
            ref="icon"
            type="file"
            style="display: none"
            accept="image/jpeg|image/png|image/gif|text/svg"
            @change="handleFile"
          )
          .d-flex
            .img-preview.img-preview-icon
              img.cursor-pointer.icon-preview-image(
                :src="imageSrc"
                @click.prevent="$refs.icon.click()"
              )
            .d-flex.align-items-end.ml-4
              button.btn.btn-secondary(@click.prevent="$refs.icon.click()") {{ $t('browse') }}
    .mb-4(style="background: white")
      Targeting(
        :show="true"
        @change="settings.frontendRules = $event"
        :rules="settings.frontendRules"
      )
  template(slot="modal-footer")
    .d-flex.justify-content-center
      om-button.mr-3(:disabled="saving" @click="$modal.hide('segments-superadmin-modal')") {{ $t('cancel') }}
      om-button(primary @click="upsert" :disabled="!settings.frontendRules.length || saving") {{ isNew ? $t('create') : $t('save') }}
</template>

<script>
  import { required } from 'vuelidate/lib/validators';
  import fileUploadMixin from '@/mixins/file-upload';
  import Targeting from '../../Segments/Targeting.vue';

  const SHOW_FOR_VALUES = ['shopify', 'non-shopify', 'all'];

  const settings = () => ({
    name: {
      en: '',
      hu: '',
    },
    frontendRules: [],
    icon: null,
    type: 'predefined',
    showFor: null,
  });

  export default {
    components: { Targeting },
    mixins: [fileUploadMixin],
    props: {
      saving: {
        type: Boolean,
        required: true,
      },
    },
    validations: {
      settings: {
        name: {
          hu: { required },
          en: { required },
        },
        showFor: { required },
      },
    },
    data: () => ({
      isNew: false,
      settings: settings(),
      iconFile: null,
      iconSrc: 'https://cdn-content.optimonk.com/shared-assets/om_picture.svg',
    }),
    computed: {
      title() {
        return this.isNew
          ? 'Create segment'
          : `Edit '${this.settings.name[this.$i18n.locale]}' segment`;
      },
      imageSrc() {
        return this.iconSrc.includes('om_picture.svg') && this.settings.icon
          ? this.settings.icon
          : this.iconSrc;
      },
      showForOptions() {
        return SHOW_FOR_VALUES.map((key) => {
          return { key, value: this.$t(`templateManager.segments.showFor.${key}`) };
        });
      },
    },
    methods: {
      parseSegment(segment) {
        if (!segment) return {};
        const clonedSegment = JSON.parse(JSON.stringify(segment));
        return {
          ...clonedSegment,
          showFor: this.showForOptions.find(({ key }) => key === clonedSegment.showFor),
          createdAt: undefined,
          updatedAt: undefined,
        };
      },
      onBeforeOpen(e) {
        const { isNew = false, segment = {} } = e.params;
        this.isNew = isNew;
        this.settings = {
          ...settings(),
          ...this.parseSegment(segment),
        };
      },
      onBeforeClose() {
        this.iconSrc = 'https://cdn-content.optimonk.com/shared-assets/om_picture.svg';
      },
      handleFile() {
        const input = this.$refs.icon;

        if (input.files.length > 0) {
          this.iconFile = input.files[0];

          const reader = new FileReader();
          reader.onload = (e) => {
            this.iconSrc = e.target.result;
          };
          reader.readAsDataURL(this.iconFile);
        }

        input.value = null;
      },
      async upsert() {
        this.$v.$touch();

        if (this.$v.$error) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
          return;
        }

        if (this.iconFile) {
          const url = await this.uploadFile(this.iconFile, 'upload/segment-icons');
          this.settings.icon = url;
        }

        const settings = this.settings;

        if (!SHOW_FOR_VALUES.includes(settings.showFor)) {
          settings.showFor = settings.showFor.key;
        }

        this.$emit('upsert', {
          isNew: this.isNew,
          segment: settings,
        });
      },
    },
  };
</script>

<style lang="sass">
  .icon-preview-image
    height: 8rem
    width: 8rem
    object-fit: contain
    border: 1px solid black
    border-radius: 3px
    background: white
    padding: 3px
</style>
