<template lang="pug">
om-modal(name="new-template" @beforeOpen="beforeOpen" @beforeClose="beforeClose" :width="960")
  template(slot="modal-header")
    .row
      .col
        .title {{ $t(`templateManager.modals.${mode}.title`) }}
    .brand-modal-action-icon
      span(class="cursor-pointer" @click="$modal.hide('new-template')" title="close" aria-hidden="true")
        close-icon(:width="12" :height="12")
  template(slot="modal-body")
    template(v-if="mode !== 'edit'")
      .mb-4
        .form-group.mb-0.row
          .col-sm-12.col-form-label.px-2 {{ $t('templateManager.selectWidgetType') }}
        .form-group.mb-0.row.align-items-center.justify-content-around
          .template-manager-widget-type-holder(v-for="(type, i) in widgetTypes")
            .template-manager-widget-type(:class="{selected: isTypeSelected(type), blurred: isBlurred(type)}" @click="handleWidgetSelect(type)")
              img.template-manager-widget-type-image(:src="require(`@/assets/admin/svg/templateManager/${type}.svg`)" width="50" height="50")
              .template-manager-widget-type-name {{ $t(type) }}
        .row.mt-2
          .col-sm-12
            .text-danger.text-left.font-size-0--8125(v-if="$v.selectedWidget.$error") {{ $t('templateManager.errors.widgetNotSelected') }}
      .form-group.mb-0.row.align-items-center.mb-4(v-if="!agency")
        .col-sm-3.col-form-label
          label {{ $t('templateManager.tabs.universal') }}
        .col-sm-9
          toggle-button(v-model="universal" sync)
    template(v-if="mode === 'edit'")
      .mt-4.empty-row
    .mb-4(v-show="!universal")
      .form-group.mb-0.row.align-items-center
        .col-sm-3.col-form-label
          label.mb-0(for="templateName") {{ $t('name') }}
        .col-sm-9
          input.form-control.form-control-md(id="templateName" type='text' :placeholder="$t('templateName')" v-model="displayName")
      .row.mt-2
        .col-sm-3
        .col-sm-9
          .text-danger.text-left.font-size-0--8125(v-if="$v.displayName.$error") {{ $t('fieldRequired') }}
    .form-group.row.align-items-center.mb-4
      .col-sm-3.col-form-label
        label.mb-0(for="goals") {{ $t('goalsLabel') }}
          i.fa.fa-info-circle.mx-2(v-tooltip="{content: $t('templateManager.tooltips.goals')}" style="position:relative;color:#ababab")
      .col-sm-9
        multiselect(id="goals"
                   :options="goalsWithTranslations"
                   label="key"
                   track-by="value"
                   :placeholder="$t('templateManager.placeholders.goals')"
                   :multiple="true"
                   :show-labels="false"
                   :allow-empty="true"
                   v-model="selectedGoals")
          template(slot="selection" slot-scope="{ values, search, isOpen }")
            span.multiselect__single(v-if="values.length && !isOpen")
              strong {{values[0].key}}
              | {{ (values.length - 1) ? ` + ${values.length - 1} ${$t('more')}` : '' }}
    .form-group.row.align-items-center.mb-4(v-show="!universal")
      .col-sm-3.col-form-label
        label.mb-0(for="category") {{ $t('newCampaignFlow.categories') }}
          i.fa.fa-info-circle.mx-2(v-tooltip="{content: $t('templateManager.tooltips.categories')}" style="position:relative;color:#ababab")
      .col-sm-9
        multiselect(id="category"
                   :options="catsWithTranslations"
                   label="key"
                   track-by="value"
                   :placeholder="$t('templateManager.placeholders.categories')"
                   :multiple="true"
                   :show-labels="false"
                   :allow-empty="true"
                   v-model="selectedCategories"
                   open-direction="bottom"
                    :class="{'is-invalid': $v.selectedCategories && $v.selectedCategories.$error}")
          template(slot="selection" slot-scope="{ values, search, isOpen }")
            span.multiselect__single(v-if="values.length && !isOpen")
              strong {{values[0].key}}
              | {{ (values.length - 1) ? ` + ${values.length - 1} ${$t('more')}` : '' }}
    template(v-if="dbID === 44")
      .form-group.row.align-items-center.mb-4
        .col-sm-3.col-form-label
          label.mb-0(for="theme") {{ $t('templateManager.titles.theme') }}
            i.fa.fa-info-circle.mx-2(v-tooltip="{content: $t('templateManager.tooltips.theme')}" style="position:relative;color:#ababab")
        .col-sm-9
          multiselect(id="theme"
                    :options="mappedThemes"
                    label="key"
                    track-by="value"
                    :placeholder="$t('templateManager.placeholders.theme')"
                    :multiple="false"
                    :show-labels="false"
                    :allow-empty="false"
                    v-model="selectedTheme"
                    open-direction="bottom")
            template(slot="selection" slot-scope="{ values, search, isOpen }")
              span.multiselect__single(v-if="values.length && !isOpen")
                strong {{values[0].key}}
                | {{ (values.length - 1) ? ` + ${values.length - 1} ${$t('more')}` : '' }}
      .form-group.row.align-items-center.mb-4
        .col-sm-3.col-form-label
          label.mb-0(for="use-case") {{ $t('templateManager.titles.useCase') }}
            i.fa.fa-info-circle.mx-2(v-tooltip="{content: $t('templateManager.tooltips.useCase')}" style="position:relative;color:#ababab")
        .col-sm-9
          multiselect(id="use-case"
                    :options="mappedUseCases"
                    label="key"
                    track-by="value"
                    :placeholder="$t('templateManager.placeholders.useCase')"
                    :multiple="false"
                    :show-labels="false"
                    :allow-empty="true"
                    v-model="selectedUseCase"
                    open-direction="bottom"
                    :class="{'is-invalid': $v.selectedUseCase && $v.selectedUseCase.$error}")
            template(slot="selection" slot-scope="{ values, search, isOpen }")
              span.multiselect__single(v-if="values.length && !isOpen")
                strong {{values[0].key}}
                | {{ (values.length - 1) ? ` + ${values.length - 1} ${$t('more')}` : '' }}
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-sm-3.col-form-label
            label.mb-0 {{ $t('templateTags') }}
          .col-sm-9
            template-tags(v-model="tags" mapArray)
      .mb-4
        .form-group.mb-0.row.align-items-center
          .col-sm-3.col-form-label
            label.mb-0(for="thumbnail") {{ $t('useCaseManager.labels.thumbnail') }}
              i.fa.fa-info-circle.mx-2(v-tooltip="{content: $t('templateManager.tooltips.thumbnail')}" style="position:relative;color:#ababab")
          .col-sm-9.align-self-end
            .img-preview.img-preview-logo.mb-4(v-if="thumbnailSrc")
              img.cursor-pointer(ref="thumbnail" :src="thumbnailSrc" @click.prevent="$refs.thumbnail.click()")
            input(ref="thumbnailInput" type="file" style='display: none' accept="image/jpeg|image/png|image/gif|text/svg" @change="handleFileSelect")
            button.btn.btn-secondary(@click.prevent="$refs.thumbnailInput.click()") {{ $t('browse') }}
        //- .row.mt-2
          .col-sm-3
          .col-sm-9
            .text-danger.text-left.font-size-0--8125(v-if="$v.thumbnailSrc.$error") {{ $t('fieldRequired') }}
    .form-group.row.align-items-center.mb-4(v-show="!universal")
      .col-sm-3.col-form-label
        label.mb-0(for="users") {{ $t('templateManager.shareWith') }}
          i.fa.fa-info-circle.mx-2(v-tooltip="{content: $t('templateManager.tooltips.shareWith')}" style="position:relative;color:#ababab")
      .col-sm-9
        multiselect(id="users"
                   :options="canShareWith"
                   label="key"
                   track-by="value"
                   :placeholder="$t('templateManager.all')"
                   :multiple="true"
                   :show-labels="false"
                   :allow-empty="true"
                   v-model="selectedUsers"
                   open-direction="bottom")
          template(slot="selection" slot-scope="{ values, search, isOpen }")
            span.multiselect__single(v-if="values.length && !isOpen")
              strong {{values[0].key}}
              | {{ (values.length - 1) ? ` + ${values.length - 1} ${$t('more')}` : '' }}
    .form-group.row.align-items-center.mb-4(v-if="!agency")
      div.col-sm-3.col-form-label
        label {{ $t('language') }}
      .col-sm-9
        .d-flex.btn-group.btn-group-toggle.brand-settings-group(data-toggle='buttons')
          label(v-for="(l, key) in {hu: 'hungarian', en: 'english'}"
                class="flex-grow-1 btn btn-secondary"
                :class="{'active': locale === key}")
            input(type="radio"
                  autocomplete="off"
                  :value="key"
                  v-model="locale")
            .d-flex.align-items-center.justify-content-center
              .iti-flag.mr-2(:class="{'hu': key === 'hu', 'us': key === 'en'}")
              div {{ $t(l) }}
  template(slot="modal-footer")
    .d-flex.justify-content-center
      om-button(primary @click="save") {{ $t(mode !== 'edit' ? 'next' : 'save') }}
</template>

<script>
  import { getAccountIdFromCookie } from '@/util';
  import CHANGE_TEMPLATE from '@/graphql/ChangeTemplate.gql';
  import NEW_TEMPLATE from '@/graphql/NewTemplate.gql';
  import templateManager from '@/mixins/templateManager';
  import { required, requiredIf } from 'vuelidate/lib/validators';
  import { mapState } from 'vuex';
  import { get as _get } from 'lodash-es';
  import { nanoid } from 'nanoid';
  import TemplateTags from '@/components/Template/TemplateTags';
  import fileUploadMixin from '@/mixins/file-upload';

  export default {
    components: { TemplateTags },

    mixins: [templateManager, fileUploadMixin],

    props: {
      goals: { type: Array },
      categories: { type: Array },
      families: { type: Array },
      useCases: { type: Array },
      users: { type: Array },
      // eslint-disable-next-line
      _locale: { type: String },
    },

    data() {
      return {
        name: '',
        displayName: '',
        selectedGoals: [],
        selectedCategories: [],
        selectedTheme: [],
        selectedUseCase: null,
        locale: '',
        mode: 'new',
        templateId: null,
        template: null,
        customError: '',
        loading: false,
        agency: false,
        subAccounts: [],
        selectedUsers: [],
        selectedWidget: null,
        universal: false,
        thumbnailSrc: null,
        thumbnailFile: null,
        tags: [],
      };
    },

    validations: {
      name: {
        required,
      },
      displayName: {
        requiredIf: requiredIf(function () {
          return !this.isUniversal;
        }),
      },
      selectedWidget: {
        requiredIf: requiredIf(function () {
          return this.mode === 'new';
        }),
      },
      /*
      thumbnailSrc: {
        requiredIf: requiredIf(function () {
          return this.isUniversal;
        }),
      }, */
      /* ,
    selectedTheme: {
      requiredIf: requiredIf(function () { return this.isUniversal })
    },
    selectedUseCase: {
      requiredIf: requiredIf(function () { return this.isUniversal })
    }
    */
    },

    computed: {
      ...mapState({
        accountId: (state) => _get(state, 'account.databaseId', '0'),
        features: (state) => _get(state, 'account.features', []),
      }),
      dbID() {
        let dbID = 0;

        try {
          dbID = parseInt(this.accountId, 10);
        } catch (e) {
          // error
        }

        return dbID;
      },
      widgetTypes() {
        const types = ['popup', 'interstitial', 'nanobar', 'sidebar', 'embedded'];

        return types;
      },
      goalsWithTranslations() {
        return (this.goals || []).map((name) => ({
          key: this.$t(`templateFilter.goals.${name}`),
          value: name,
        }));
      },
      catsWithTranslations() {
        return (this.categories || []).map((name) => ({
          key: this.$t(`templateFilter.categories.${name}`),
          value: name,
        }));
      },
      canShareWith() {
        return this.users.map((user) => ({
          key: user.name,
          value: user._id,
        }));
      },
      mappedThemes() {
        return (this.families || []).map(({ name }) => ({ key: name, value: name }));
      },
      mappedUseCases() {
        return (this.useCases || []).map(({ name, _id }) => ({
          key: name[this.$i18n.locale],
          value: _id,
        }));
      },
      isUniversal() {
        return this.universal;
      },
    },

    watch: {
      name() {
        if (this.customError.length) {
          this.customError = '';
        }
      },
    },
    methods: {
      async beforeOpen(event) {
        this.name = nanoid(9);
        this.displayName = '';
        this.selectedGoals = [];
        this.mode = 'new';
        this.customError = '';
        this.loading = false;
        this.$v.$reset();
        this.selectedUsers = [];
        this.selectedCategories = [];
        this.selectedGoals = [];
        this.locale = this._locale;
        this.thumbnailImage = null;
        this.thumbnailFile = null;
        this.selectedTheme = null;
        this.selectedUseCase = null;
        this.tags = [];
        let universal;

        if (event.params) {
          if (event.params.template) {
            universal = !!event.params.template.universal;
            const { template } = event.params;
            this.mode = 'edit';
            this.displayName = template.displayName;
            this.name = template.name;
            this.selectedGoals = (template.goals || []).map((g) => ({
              key: this.$t(`templateFilter.goals.${g}`),
              value: g,
            }));
            this.selectedCategories = (template.categories || []).map((c) => ({
              key: this.$t(`templateFilter.categories.${c}`),
              value: c,
            }));
            this.locale = template.locale;
            this.templateId = template._id;
            (template.users || []).forEach((id) => {
              const userIndex = this.users.findIndex((u) => u._id === id);
              if (userIndex !== -1) {
                this.selectedUsers.push({
                  key: this.users[userIndex].name,
                  value: id,
                });
              }
            });
            this.tags = template.tags || [];
            this.template = template;
            if (this.template.theme) {
              const name = this.template.theme;
              this.selectedTheme = { key: name, value: name };
            }
            const uc = this.template.useCase;
            if (uc) {
              const found = this.mappedUseCases.find(({ value }) => value === uc);
              this.selectedUseCase = found || null;
            }

            if (this.template.thumbnail) {
              this.thumbnailSrc = this.template.thumbnail[0];
            } else {
              this.thumbnailSrc = null;
            }
          }

          if (event.params.agency) this.agency = event.params.agency;
          this.universal = universal || false;

          if (this.universal) this.displayName = this.displayName || nanoid(9);
        }
      },

      isTypeSelected(num) {
        return this.selectedWidget === num;
      },

      isBlurred(num) {
        return this.selectedWidget && !this.isTypeSelected(num);
      },

      handleWidgetSelect(num) {
        this.selectedWidget = this.selectedWidget === num ? null : num;
      },

      async save() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.loading = true;
        const variables = {
          displayName: this.displayName,
          name: this.name,
          goals: this.selectedGoals.map((item) => item.value),
          locale: this.locale,
          categories: this.selectedCategories.map((item) => item.value),
          type: this.selectedWidget,
          users: this.selectedUsers.map((item) => item.value),
          tags: this.tags,
        };

        if (this.selectedUseCase && this.selectedUseCase.value) {
          variables.universal = {
            ...variables.universal,
            useCase: this.selectedUseCase.value,
          };
        } else if (this.isUniversal) {
          variables.universal = {
            ...variables.universal,
            useCase: null,
          };
        }

        if (this.selectedTheme && this.selectedTheme.value) {
          variables.universal = {
            ...variables.universal,
            theme: this.selectedTheme.value,
          };
        } else if (this.isUniversal) {
          variables.universal = {
            ...variables.universal,
            theme: null,
          };
        }

        if (this.universal) {
          variables.universal = {
            ...variables.universal,
            universal: true,
          };
        }

        if (this.thumbnailFile) {
          const thumbnail = await this.uploadFile(this.thumbnailFile, 'upload/template-thumbnails');
          this.thumbnailSrc = thumbnail;
          variables.universal = {
            ...variables.universal,
            thumbnail,
          };
        }

        if (this.mode === 'new') {
          const {
            data: {
              newTemplate: { success, message },
            },
          } = await this.$apollo.mutate({
            mutation: NEW_TEMPLATE,
            variables,
          });
          this.loading = false;

          if (success) {
            this.$router.push({
              name: 'template_editor',
              params: { baseTemplate: this.name, userId: getAccountIdFromCookie() },
            });
          } else if (message === 'templateNameExists') {
            this.$refs.nameInput.classList.add('is-invalid');
            this.customError = this.$t('templateNameExists');
          }
        } else {
          variables.templateId = this.templateId;
          delete variables.type;

          const {
            data: {
              changeTemplate: { success, message },
            },
          } = await this.$apollo.mutate({
            mutation: CHANGE_TEMPLATE,
            variables,
          });
          this.loading = false;

          if (success) {
            this.$emit('templateChanged', {
              templateId: this.templateId,
              type: this.getTemplateType(this.template),
              displayName: this.displayName,
              name: this.name,
              goals: this.selectedGoals.map((item) => item.value),
              categories: this.selectedCategories.map((item) => item.value),
              locale: this.locale,
              users: this.selectedUsers.map((item) => item.value),
              useCase: _get(this.selectedUseCase, 'value', null),
              theme: _get(this.selectedTheme, 'value', null),
              thumbnail: this.thumbnailSrc,
              tags: this.tags,
            });
            this.$modal.hide('new-template');
          } else if (message === 'templateNameExists') {
            this.$refs.nameInput.classList.add('is-invalid');
            this.customError = this.$t('templateNameExists');
          }
        }
      },
      beforeClose() {
        this.selectedWidget = null;
      },
      handleFileSelect() {
        const input = this.$refs.thumbnailInput;

        if (input.files.length > 0) {
          this.thumbnailFile = input.files[0];

          const reader = new FileReader();
          reader.onload = (e) => {
            this.thumbnailSrc = e.target.result;
          };
          reader.readAsDataURL(this.thumbnailFile);

          input.value = null;
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  .template-manager
    &-widget-type
      text-align: center
      border-radius: 3px
      box-shadow: 0px 10px 30px rgba(9, 46, 117, 0.07)
      cursor: pointer
      border: 2px solid transparent
      &-holder
        max-width: calc(20% - 1rem)
        position: relative
        width: 100%
      &.selected
        border: 2px solid var(--brand-primary-color)
      &.blurred:after
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background: rgba(255,255,255,.8)
        display: block
        content: ""
        pointer-events: none
      &-image
        width: 100%
        height: auto
      &-name
        padding: .25rem 0
        font-size: 0.9375rem
</style>

<style lang="sass">
  .tags-input
    height: auto !important
    max-height: none !important
    line-height: normal !important
    padding: 0.375rem 1.25rem !important
    justify-content: center !important

    span
      margin-bottom: 0.3rem !important
    input
      font-size: 0.75rem
</style>
