<template lang="pug">
.use-case-row.row.p-2
  .use-case-heading.col-12.d-flex.cursor-pointer
    .use-case-title.font-weight-bold.pr-3 {{ templateCollection.name[$i18n.locale] }}
      .use-case-action-icon.cursor-pointer(@click.stop="$emit('edit', templateCollection)")
        fa-icon(variant="fa-pencil")
      .use-case-action-icon.cursor-pointer(@click.stop="$emit('delete', templateCollection)")
        fa-icon(variant="fa-trash")
      .use-case-action-icon.cursor-pointer(@click.stop="openLink")
        fa-icon(variant="fa-link")
</template>

<script>
  import runtimeConfig from '@/config/runtime';

  export default {
    props: {
      templateCollection: {
        type: Object,
        default: () => ({}),
      },
    },

    methods: {
      openLink() {
        const link = `${runtimeConfig.VUE_APP_TEMPLATE_LIBRARY_URL}/collections/${this.templateCollection.slug}`;
        Object.assign(document.createElement('a'), { target: '_blank', href: link }).click();
      },
    },
  };
</script>
