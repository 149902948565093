<template lang="pug">
om-modal.rule-is-part-of-experience-warning(
  name="rule-is-part-of-experience-warning"
  :width="420"
  color="light"
  @beforeOpen="beforeOpen"
  :headerBorder="true"
  :clickToClose="true"
)
  template(slot="modal-header")
    .brand-modal-action-icon
      close-icon.cursor-pointer(
        @click.native="$modal.hide('rule-is-part-of-experience-warning')"
        :width="14"
        :height="14"
        color="#C2C2C2"
      )
    om-heading(h5) {{ $t('experiences.ruleIsPartOfExperienceWarning.title') }}
  template(slot="modal-body")
    om-body-text.mb-5(
      bt400md
      v-html="$t('experiences.ruleIsPartOfExperienceWarning.description', { ruleName })"
    )
    .dont-show-again.form-check
      input#dont-show-again.form-check-input(type="checkbox" v-model="dontShowModal")
      label.form-check-label(for="dont-show-again") {{ $t('experiences.ruleIsPartOfExperienceWarning.dontShowAgain') }}
  template(slot="modal-footer")
    .d-flex.justify-content-end.align-center
      om-button#ruleIsPartOfExperienceWarningCancel.mr-4(
        ghost
        @click.native="$modal.hide('rule-is-part-of-experience-warning')"
      ) {{ $t('cancel') }}
      om-button#ruleIsPartOfExperienceWarningModalContinue(primary @click="continueAddRule()") {{ $t('continue') }}
</template>
<script>
  import cookie from '@/mixins/cookie';
  import { mapGetters } from 'vuex';

  export default {
    mixins: [cookie],
    data() {
      return {
        ruleType: '',
        dontShowModal: false,
      };
    },
    computed: {
      ...mapGetters(['brandName']),
      ruleName() {
        let transKey = this.ruleType;

        if (transKey === 'campaignProgressState') {
          transKey += '.turbo';
        } else if (transKey === 'visitorCartRevamp') {
          transKey = 'visitorCart';
        }

        return this.$t(`frontendRules.${transKey}.title`, { brand: this.brandName });
      },
    },
    methods: {
      continueAddRule() {
        const ruleType = this.ruleType;
        if (this.dontShowModal) {
          this.setCookie('x-om-show-rule-is-part-of-experience-modal', 0, 360); // expire after 360 days
        }
        this.$emit('continueAddRule', { ruleType });
      },
      async beforeOpen(event) {
        const ruleType = event.params.ruleType;
        this.ruleType = ruleType;
      },
    },
  };
</script>
<style lang="sass">
  .dont-show-again
    display: flex
    justify-content: flex-start
    align-items: center
</style>
