<template lang="pug">
.use-case-filter.d-flex.mt-2.mb-5.row.template-filter-agency
  .use-case-filter-dropdown.col-sm-3.mb-2.mb-md-0(v-for="item in items")
    multiselect(
      v-if="item.type === 'dropdown'"
      :id="item.key"
      :options="itemOptions(item.key)"
      label="key"
      track-by="value"
      :placeholder="itemPlaceholder(item.key)"
      :multiple="isMultiselect(item.key)"
      :show-labels="false"
      :allow-empty="true"
      v-model="filter[item.key]"
    )
    input.form-control(
      v-else
      type="text"
      v-model="filter[item.key]"
      :placeholder="$t(`useCaseManager.placeholders.${item.key}`)"
    )
</template>

<script>
  import { get as _get } from 'lodash-es';

  export default {
    props: {
      domain: { type: String, default: 'useCaseManager.placeholders' },
      filter: { type: Object, required: true },
      options: { type: [Object, null], default: null },
    },
    data: () => ({
      items: [],
      transDomain: 'useCaseManager',
    }),
    watch: {
      filter: {
        handler(n) {
          this.$emit('update:filter', n);
        },
        deep: true,
        immediate: true,
      },
    },
    created() {
      this.items = Object.entries(this.filter).map(([key, value]) => {
        const isSelect = _get(this.options, `${key}.select`, false);
        const element = { key, type: 'text' };

        if (isSelect || Array.isArray(value)) {
          const values = _get(value, 'values', this.options[key]) || [];
          element.options = values;
          element.type = 'dropdown';
        }

        return element;
      });
    },
    methods: {
      itemPlaceholder(key) {
        const opts = this.options[key];
        return (opts && opts.placeholder) || this.$t(`${this.domain}.${key}`);
      },
      itemOptions(key) {
        const opts = this.options[key];
        return Array.isArray(opts) ? opts : opts.values;
      },
      isMultiselect(key) {
        return _get(this.options, `${key}.multi`, true);
      },
    },
  };
</script>
