export default {
  data() {
    return {
      selectedTemplates: [],
    };
  },

  methods: {
    templateSelected(templateId) {
      this.$set(this, 'selectedTemplates', [...this.selectedTemplates, templateId]);
    },
    templateUnselected(templateId) {
      this.selectedTemplates = this.selectedTemplates.filter((t) => t !== templateId.toString());
    },
  },
};
