<template lang="pug">
.use-case-row.row.p-2
  .use-case-heading.col-12.d-flex.cursor-pointer.align-items-center(@click="opened = !opened")
    .use-case-title.pr-3.font-weight-bold.align-items-center
      img.row-image(:src="segment.icon")
      .ml-3.mb-0 {{ name[$i18n.locale] }}
        om-tag {{ segment.showFor }}
      .use-case-action-icon.cursor-pointer(@click.stop="$emit('edit', segment._id)")
        fa-icon(variant="fa-pencil")
      .use-case-action-icon.cursor-pointer(@click.stop="onDelete")
        fa-icon(variant="fa-trash")
    .use-case-chevron
      fa-icon(:variant="opened ? 'fa-chevron-up' : 'fa-chevron-down'")
  transition(name="om-fade")
    div(v-if="opened")
      .card.mt-5(v-if="rules && rules.length")
        .card-body.brand-settings-summary
          .brand-settings-summary-title {{ $t('settingsSummary.frontendRuleTitle') }}
          .pl-5
            template(v-for="(frontendRule, frontendRuleIndex) in rules")
              .d-flex.align-items-center.brand-settings-summary-row(
                :class="getRowLineType(frontendRuleIndex)"
              )
                frontend-rule-box(
                  :frontendRule="frontendRule"
                  :campaign="{ domain: 'optimonk.com' }"
                  small
                )
                .rule-logical-condition.rule-logical-condition-and(
                  v-if="frontendRuleIndex < rules.length - 1"
                  :class="`rule-logical-condition-and-${$i18n.locale}`"
                ) {{ $t('and') }}
</template>

<script>
  import FrontendRuleBox from '@/components/CampaignSettings/FrontendRuleBox';
  import getRowLineType from '@/mixins/getRowLineType';

  export default {
    components: { FrontendRuleBox },
    mixins: [getRowLineType],
    props: {
      segment: { type: Object, required: true },
    },
    data: () => ({ opened: false }),
    computed: {
      name() {
        return this.segment.name;
      },
      rules() {
        return this.segment.frontendRules;
      },
    },
    methods: {
      onDelete() {
        const { _id: id, type } = this.segment;
        this.$emit('delete', { id, type });
      },
    },
  };
</script>

<style lang="sass">
  .row-image
    width: 4rem
    height: 4rem
    object-fit: cover
  .brand-settings-summary
    padding: 2.1875rem 2.5rem 2rem !important
    font-size: 0.875rem
    position: relative
    border: 1px solid #D8DDE1
    border-radius: 3px

    &-title
      font-size: .9375rem
      font-weight: 500
      text-transform: uppercase
      color: #17181A
      margin-bottom: 1.25rem

  .brand-settings-summary-row
    position: relative
    margin-bottom: 1rem
    &:last-child
      margin-bottom: 0

    &:before,
    &:after
      position: absolute
      content: ''
      width: 2px
      height: 50%
      background: #D8DDE1
      top: 0
      left: -1.5rem
      max-height: 50%
      cursor: default !important
      pointer-events: none !important
    &:after
      top: 50%

    &-hide-line
      &:before,
      &:after
        content: none

    .rule-logical-condition
      bottom: -.5rem

      &-or
        &-hu
          left: -2.6875rem
        &-en
          left: -2.25rem
      &-and
        &-hu
          left: -2.187rem
        &-en
          left: -2.5rem

    &-line
      &.brand-settings-summary-row-line-end
        &:before
          content: ''
          top: 0
        &:after
          content: none
      .brand-rule-box
        &:before
          position: absolute
          content: ''
          width: 0.9375rem
          height: 2px
          background: #D8DDE1
          top: 50%
          left: -1.5rem
          cursor: default !important
          pointer-events: none !important
      &.brand-settings-summary-row-line-start
        .brand-rule-box
          &:before
            content: none
</style>
