<template lang="pug">
div
  SegmentRow(
    v-for="segment in segments"
    :segment="segment"
    :key="segment._id"
    @edit="onEdit"
    @delete="onDelete"
  )
  SuperadminModal(@upsert="onUpsert" :saving="saving")
</template>

<script>
  import SuperadminModal from '@/components/Modals/Segments/SuperadminModal.vue';
  import SegmentRow from '@/components/Segments/Row.vue';
  import SEGMENTS from '@/graphql/Segments.gql';
  import UPSERT_SEGMENT from '@/graphql/UpsertSegment.gql';
  import DELETE_SEGMENT from '@/graphql/DeleteSegment.gql';
  import { getBrandedClassString } from '@/components/Elements/Button';

  export default {
    components: { SuperadminModal, SegmentRow },
    data: () => ({ saving: false }),
    apollo: {
      segments: {
        query: SEGMENTS,
        variables: { type: 'predefined' },
      },
    },
    methods: {
      async onUpsert({ segment }) {
        if (this.saving) return;
        this.saving = true;
        const {
          data: { result },
        } = await this.$apollo.mutate({
          mutation: UPSERT_SEGMENT,
          variables: { segment },
        });

        await this.$apollo.queries.segments.refresh();
        this.saving = false;

        if (result) this.$modal.hide('segments-superadmin-modal');
      },
      onEdit(id) {
        const segment = this.segments.find(({ _id }) => id === _id);
        if (!segment) return;
        this.$modal.show('segments-superadmin-modal', { segment });
      },
      async onDelete(variables) {
        this.$modal.show('dialog', {
          text: this.$t('confirmationDialog'),
          buttons: [
            {
              title: this.$t('yes'),
              class: 'btn btn-primary mr-3 steve',
              handler: () => {
                this.$apollo
                  .mutate({
                    mutation: DELETE_SEGMENT,
                    variables,
                  })
                  .then(async () => {
                    await this.$apollo.queries.segments.refresh();
                    this.$modal.hide('dialog');
                  })
                  .catch(console.error);
              },
            },
            {
              title: this.$t('cancel'),
              class: getBrandedClassString({ secondary: true }),
              default: true,
            },
          ],
        });
      },
    },
  };
</script>
