export default {
  computed: {
    tacticGoalsOpts() {
      return this.getTacticTranslationOptions('goal');
    },
    tacticIndustryOpts() {
      return this.getTacticTranslationOptions('industry');
    },
    tacticFeatureOpts() {
      return this.getTacticTranslationOptions('feature');
    },
  },
  methods: {
    getTacticTranslationOptions(property) {
      const translationKey = `tactic.${property}`;
      const options = this.$te(translationKey) ? this.$t(translationKey) : {};
      return Object.entries(options).map(([value, key]) => ({ key, value }));
    },
  },
};
