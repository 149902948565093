<template lang="pug">
.campaign-setting-card.card.last-child.campaign-setting-card-skeleton(
  :class="(type === 'integration' ? 'campaign-setting-card-no-line last-child' : '', !showStructureLines ? 'no-structure-lines' : '')"
)
  .card-body.px-0(:class="type !== 'integration' ? 'card-body-line' : ''")
    .position-relative
      img(v-if="type === 'integration'" :src="require('@/assets/admin/svg/dummy-integration.svg')")
      img(v-else :src="require('@/assets/admin/svg/dummy-rule.svg')")
      .d-flex.align-items-center.brand-link(@click="$emit('click')")
        add-svg.mr-2
        span {{ getButtonText }}
</template>

<script>
  import AddSvg from '@/components/Svg/Icons/Add';

  export default {
    components: {
      AddSvg,
    },

    props: {
      type: {
        type: String,
      },
      showStructureLines: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      getButtonText() {
        let text = '';

        if (this.type === 'event') text = this.$t('addMoreTrigger');
        else if (this.type === 'frontendRule') text = this.$t('addNewCondition');
        else text = this.$t('addNewIntegration');

        return text;
      },
    },
  };
</script>
