<template lang="pug">
om-modal(name="name-duplicate-template" color="light" @beforeOpen="beforeOpen")
  template(slot="modal-header")
    .row
      .col
        .title.text-left {{ $t('duplicateTemplate') }}
    .brand-modal-action-icon
      span.cursor-pointer(
        @click="$modal.hide('name-duplicate-template')"
        title="close"
        aria-hidden="true"
      )
        close-icon(:width="12" :height="12")
  template(slot="modal-body")
    .row.mt-3
      .col
        .form-group.col
          label.input-label.input-label {{ $t('displayName') }}
          input.form-control.form-control-md(
            :disabled="universal ? 'disabled' : null"
            v-model="displayName"
          )
        .form-group
          .brand-input-error(v-if="customError && customError.length") {{ customError }}
  template(slot="modal-footer")
    .d-flex.justify-content-center
      om-button.px-5(primary large :class="{ loading: loading }" @click="duplicate") {{ $t('save') }}
</template>

<script>
  import DUPLICATE_TEMPLATE from '@/graphql/DuplicateTemplate.gql';
  import { required } from 'vuelidate/lib/validators';
  import { nanoid } from 'nanoid';

  export default {
    data() {
      return {
        displayName: '',
        name: '',
        customError: '',
        loading: false,
        universal: false,
      };
    },

    validations: {
      displayName: {
        required,
      },
    },

    watch: {
      name() {
        if (this.customError.length) {
          this.customError = '';
        }
      },
    },

    methods: {
      beforeOpen(event) {
        this.templateId = event.params.templateId;
        this.universal = event.params.universal;
        this.displayName = this.universal ? nanoid(9) : '';
        this.name = '';
        this.customError = '';
        this.loading = false;
        this.$v.$reset();
      },

      async duplicate() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }

        this.loading = true;

        const {
          data: {
            duplicateTemplate: { success, message, template },
          },
        } = await this.$apollo.mutate({
          mutation: DUPLICATE_TEMPLATE,
          variables: {
            templateId: this.templateId,
            displayName: this.displayName,
            name: nanoid(9),
          },
        });

        this.loading = false;

        if (success) {
          this.$emit('duplicated', { template: { ...template, users: [] } });
          this.$modal.hide('name-duplicate-template');
        } else if (message === 'templateNameExists') {
          this.$refs.nameInput.classList.add('is-invalid');
          this.customError = this.$t('templateNameExists');
        }
      },
    },
  };
</script>
