<template lang="pug">
  om-modal(name="new-template-collection"
           @beforeOpen="onBeforeOpen"
           @beforeClose="onBeforeClose"
           :clickToClose="false"
           :width="800")
    template(#modal-header)
      .row
        .col
          .title {{ $t(`templateCollection.titles.${mode}`) }}

      .brand-modal-action-icon
        span(class="cursor-pointer" @click="$modal.hide('new-template-collection')" title="close" aria-hidden="true")
          close-icon(:width="12" :height="12")
    template(#modal-body)
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-sm-3.col-form-label
            label.mb-0(for="name-en") {{ $t('name') }} (EN)
          .col-sm-9
            input.form-control.form-control-md(id="name-en" type='text' v-model="settings.name.en")
        .row.mt-2
          .col-sm-3
          .col-sm-9
            .text-danger.text-left.font-size-0--8125(v-if="$v.settings.name.en.$error") {{ $t('fieldRequired') }}
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-sm-3.col-form-label
            label.mb-0(for="name-hu") {{ $t('name') }} (HU)
          .col-sm-9
            input.form-control.form-control-md(id="name-hu" type='text' v-model="settings.name.hu")
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-sm-3.col-form-label
            label.mb-0(for="slug") {{ $t('templateCollection.labels.slug') }}
              i.fa.fa-info-circle.mx-2(v-tooltip="{content: $t('templateCollection.tooltips.slug')}" style="position:relative;color:#ababab")
          .col-sm-9
            input.form-control.form-control-md(id="slug" type='text' v-model="settings.slug")
        .row.mt-2
          .col-sm-3
          .col-sm-9
            .text-danger.text-left.font-size-0--8125(v-if="$v.settings.slug.$error") {{ $t('fieldRequired') }}
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-sm-3.col-form-label
            label.mb-0 {{ $t('templateCollection.labels.status') }}
          .col-sm-9
            toggle-button(class="my-0"
                          style="margin-bottom:0!important"
                          :value="settings.status === 'active'"
                          :sync="true"
                          :labels="{checked: $t('templateCollection.labels.active'), unchecked: $t('templateCollection.labels.inactive')}"
                          :width="80"
                          :height="24"
                          :margin="2"
                          @change.self="settings.status = $event.value ? 'active' : 'inactive'")
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-sm-3.col-form-label
            label.mb-0 {{ $t('templateCollection.labels.visibility') }}
          .col-sm-9
            toggle-button(class="my-0"
                          style="margin-bottom:0!important"
                          :value="settings.visibility === 'public'"
                          :sync="true"
                          :labels="{checked: $t('templateCollection.labels.public'), unchecked: $t('templateCollection.labels.private')}"
                          :width="80"
                          :height="24"
                          :margin="2"
                          @change.self="settings.visibility = $event.value ? 'public' : 'private'")
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-sm-3.col-form-label
            label.mb-0 {{ $t('tags') }}
          .col-sm-9
            template-tags(v-model="settings.tags" mapArray)
        .row.mt-2
          .col-sm-3
          .col-sm-9
            .text-danger.text-left.font-size-0--8125(v-if="$v.settings.tags.$error") {{ $t('fieldRequired') }}
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-sm-12.col-form-label
            label.mb-0(for="description_en") {{ $t('templateCollection.labels.description') }} (EN)
          .col-sm-12
            om-textarea#description_en(rows="4" style="width:100%" v-model="settings.description.en")
        .row.mt-2
          .col-sm-12
            .text-danger.text-left.font-size-0--8125(v-if="$v.settings.description.en.$error") {{ $t('fieldRequired') }}
      .mb-4
        .form-group.row.align-items-center.mb-0
          .col-sm-12.col-form-label
            label.mb-0(for="description_hu") {{ $t('templateCollection.labels.description') }} (HU)
          .col-sm-12
            om-textarea#description_hu(rows="4" style="width:100%" v-model="settings.description.hu")
        .row.mt-2
          .col-sm-12
            .text-danger.text-left.font-size-0--8125(v-if="$v.settings.description.hu.$error") {{ $t('fieldRequired') }}
    template(slot="modal-footer")
      .d-flex.justify-content-center
        template(v-if="isNew")
          om-button(primary @click="createOrSave()") {{ $t('create') }}
        template(v-else)
          om-button.mx-3(primary @click="createOrSave()") {{ $t('save') }}
          //- om-button(primary @click="createOrSave(true)") {{ $t('editSettings') }}
</template>

<script>
  import { required } from 'vuelidate/lib/validators';
  import { slugify } from '@/util';

  import TemplateTags from '@/components/Template/TemplateTags.vue';

  import UPSERT_TEMPLATE_COLLECTION from '@/graphql/UpsertTemplateCollection.gql';

  const baseSettings = {
    name: {
      en: '',
      hu: '',
    },
    description: {
      en: '',
      hu: '',
    },
    slug: '',
    tags: [],
    status: 'inactive',
    visibility: 'private',
  };

  export default {
    components: { TemplateTags },

    props: {
      templateCollections: {
        type: Array,
        default: () => [],
      },
    },

    validations: {
      settings: {
        name: {
          en: { required },
          hu: { required },
        },
        description: {
          en: { required },
          hu: { required },
        },
        slug: { required },
        tags: { required },
      },
    },
    data: () => ({
      nameFirstEdit: true,
      settings: {
        ...baseSettings,
      },
      mode: 'new',
    }),
    computed: {
      isNew() {
        return this.mode === 'new';
      },
    },
    watch: {
      'settings.name.en': function (n, o) {
        if (!this.settings.name.hu || this.settings.name.hu === o) {
          this.settings.name.hu = n;
        }

        if (this.nameFirstEdit) {
          this.settings.slug = slugify(n);
        }
      },
      'settings.description.en': function (n, o) {
        if (!this.settings.description.hu || this.settings.description.hu === o) {
          this.settings.description.hu = n;
        }
      },
      settings: {
        handler() {
          this.$v.$reset();
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      async onBeforeOpen(event) {
        this.mode = 'new';

        this.settings = JSON.parse(JSON.stringify(baseSettings));

        if (event.params) {
          const { mode } = event.params;
          this.mode = mode || 'new';

          if (!this.isNew) {
            const { _id, name, description, slug, tags, status, visibility } = event.params.data;

            this.settings = Object.assign(this.settings, {
              _id,
              name,
              description,
              slug,
              tags: tags || [],
              status,
              visibility,
            });

            this.nameFirstEdit = false;
          }
        } else {
          this.nameFirstEdit = true;
        }

        this.$v.$reset();
      },
      onBeforeClose() {
        this.$v.$reset();
      },
      async createOrSave() {
        this.$v.$touch();

        if (this.$v.$error) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
          return;
        }

        const {
          data: { upsertTemplateCollection },
        } = await this.$apollo.mutate({
          mutation: UPSERT_TEMPLATE_COLLECTION,
          variables: {
            ...this.settings,
            order: this.templateCollections.length + 1,
          },
        });

        if (upsertTemplateCollection.success) {
          this.$modal.hide('new-template-collection');

          if (this.isNew) {
            this.$emit('refetch');
          } else {
            this.$emit('update', this.settings);
          }
        } else {
          this.$notify({
            type: 'error',
            text: upsertTemplateCollection.message,
          });
        }
      },
    },
  };
</script>
