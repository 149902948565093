<template lang="pug">
  .use-case-row.row.p-2(:style="rowStyle")
    .use-case-heading.col-12.d-flex.cursor-pointer(@click="open")
      .use-case-title.font-weight-bold.pr-3 {{ data.name[$i18n.locale] }}
        .use-case-action-icon.cursor-pointer(@click.stop="$emit('edit', data)")
          fa-icon(variant="fa-pencil")
        .use-case-action-icon.cursor-pointer(@click.stop="$emit('delete', data)")
          fa-icon(variant="fa-trash")
        .use-case-action-button.cursor-pointer(@click.stop="editRules") {{ $t('useCaseManager.labels.editRules') }}
      .use-case-chevron
        fa-icon(:variant="opened ? 'fa-chevron-up' : 'fa-chevron-down'")
    transition(name="om-fade")
      .use-case-content-row.col-12(v-show="opened")
        .use-case-content
          .mt-1
            span.font-weight-bold {{ $t('goalsLabel') }}:
            span.use-case-tag.px-2.py-1(v-for="goal in data.goals")  {{ $t(transKey(goal, 'goals')) }}
          .mt-1
            span.font-weight-bold {{ $t('useCaseManager.labels.tactics') }}:
            span.use-case-tag.px-2.py-1(v-for="tactic in data.tactics")  {{ $t(transKey(tactic, 'tactics')) }}
          .mt-1
            span.font-weight-bold {{ $t('useCaseManager.labels.industries') }}:
            span.use-case-tag.px-2.py-1(v-for="industry in data.industries")  {{ $t(transKey(industry, 'industries')) }}
          .card.mt-5(v-if="(targeting) && (!frontendRules || !frontendRules.length)")
            .card-body.brand-settings-summary
              .brand-settings-summary-title {{ $t('useCaseManager.labels.targeting') }}
              .brand-settings-summary-title {{ targeting }}
          .card.mt-5(v-if="(!events || !events.length) && (!frontendRules || !frontendRules.length)")
            .card-body.brand-settings-summary
              .brand-settings-summary-title {{ $t('nothingToShow') }}
          .card.mt-5(v-if="events && events.length")
            .card-body.brand-settings-summary
              .brand-settings-summary-title {{ $t('settingsSummary.eventTitle') }}
              .pl-5.mt-3
                template(v-for="(event, eventIndex) in data.events")
                  .d-flex.align-items-center.brand-settings-summary-row(
                    :class="getRowLineType(eventIndex, 'events')"
                  )
                    event-box(:event="event" small)
                    .rule-logical-condition.rule-logical-condition-or(
                      v-if="eventIndex < events.length - 1"
                      :class="`rule-logical-condition-or-${$i18n.locale}`"
                    ) {{ $t('or') }}
          .card.mt-5(v-if="frontendRules && frontendRules.length")
            .card-body.brand-settings-summary
              .brand-settings-summary-title {{ $t('settingsSummary.frontendRuleTitle') }}
              .pl-5
                template(v-for="(frontendRule, frontendRuleIndex) in frontendRules")
                  .d-flex.align-items-center.brand-settings-summary-row(
                    :class="getRowLineType(frontendRuleIndex, 'frontendRules')"
                  )
                    frontend-rule-box(
                      :frontendRule="frontendRule"
                      :campaign="{ domain: 'optimonk.com' }"
                      small
                    )
                    .rule-logical-condition.rule-logical-condition-and(
                      v-if="frontendRuleIndex < frontendRules.length - 1"
                      :class="`rule-logical-condition-and-${$i18n.locale}`"
                    ) {{ $t('and') }}
</template>

<script>
  import EventBox from '@/components/CampaignSettings/EventBox';
  import FrontendRuleBox from '@/components/CampaignSettings/FrontendRuleBox';

  export default {
    components: { EventBox, FrontendRuleBox },
    props: {
      data: { type: Object, required: true },
    },
    data: () => ({ opened: false }),
    computed: {
      rowStyle() {
        return {
          'max-height': this.opened ? 'unset' : null,
          'padding-bottom': this.opened ? '1rem' : null,
        };
      },
      events() {
        return this.data.events;
      },
      frontendRules() {
        return this.data.frontendRules;
      },
      targeting() {
        const targeting = this.data.targeting;
        return targeting && targeting[this.$i18n.locale]
          ? targeting[this.$i18n.locale]
          : this.$t('nothingToShow');
      },
    },
    methods: {
      open() {
        if (this.opened) {
          this.close();
        } else {
          this.$emit('open', this);
        }
      },
      transKey(key, sub, domain = 'useCaseManager') {
        return `${domain}.${sub}.${key}`;
      },
      close() {
        this.opened = false;
      },
      getRowLineType(eventIndex, type) {
        // const rowLines = (this.campaign.frontendRules.length + this.campaign.events.length)
        const start = eventIndex === 0 && this[type].length > 1;
        const end =
          (eventIndex === this[type].length - 1 && this[type].length > 1) ||
          this[type].length === 1;
        return {
          'brand-settings-summary-row-line': start || end,
          'brand-settings-summary-row-line-start': start,
          'brand-settings-summary-row-line-end': end,
        };
      },
      editRules() {
        this.$router.push({ name: 'use_case_rules', params: { id: this.data._id } });
      },
    },
  };
</script>

<style lang="sass">
  .use-case
    &-row
      border: 1px solid #E9EFF4
      border-radius: 5px
      padding-bottom: 0
      & + &
        margin-top: 1.25rem
    &-heading
      justify-content: space-between
      align-items: center
    &-title
      font-size: 1.125rem
      display: flex
    &-chevron
      width: 3rem
      height: 3rem
      color: var(--brand-primary-color)
      display: flex
      align-items: center
      justify-content: center
    &-action
      &-icon
        width: 1.75rem
        height: 1.75rem
        display: flex
        margin-left: 1rem
        color: #bfc5d1
        justify-content: center
        align-items: center
        border-radius: 100%
        font-size: .75rem
        &:hover
          background: rgba(0,0,0,.1)
          color: var(--brand-primary-color)
      &-button
        display: flex
        justify-content: center
        align-items: center
        color: #bfc5d1
        font-size: .75rem
        padding: 0 .5rem
        border-radius: 5px
        margin-left: 1rem
        &:hover
          background: rgba(0,0,0,.1)
          color: var(--brand-primary-color)
    &-tag
      display: inline-block
      background: rgba(0,0,0,.125)
      border-radius: 3px
      margin: 0 .25rem
</style>
