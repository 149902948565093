<template lang="pug">
om-modal(name="tag-template-modal"
         :title="$t('tagTemplates')"
         :width="520"
         color="light"
         @beforeOpen="beforeOpen")
  template(slot="modal-body")
    template-tags(v-model="selectedTags")
  template(slot="modal-footer")
    om-button.mx-auto.mt-4(@click="save" :loading="loading") {{ $t('save') }}
</template>

<script>
  import TAG_TEMPLATES from '@/graphql/TagTemplates.gql';

  import TemplateTags from '@/components/Template/TemplateTags.vue';

  export default {
    components: { TemplateTags },

    data() {
      return {
        selectedTemplates: [],
        selectedTags: [],
        loading: false,
      };
    },

    methods: {
      async beforeOpen(event) {
        this.selectedTemplates = event.params.selectedTemplates;
        this.selectedTags = [];
      },
      async save() {
        if (!this.selectedTags.length) {
          return;
        }

        this.loading = true;

        const tagIds = this.selectedTags.map((t) => t._id);

        await this.$apollo.mutate({
          mutation: TAG_TEMPLATES,
          variables: {
            templateIds: this.selectedTemplates,
            tagIds,
          },
        });

        setTimeout(() => {
          this.$emit('updateTemplates', { templates: this.selectedTemplates, tags: tagIds });
          this.$emit('deselectAll');
          this.loading = false;
          this.$modal.hide('tag-template-modal');
        }, 200);
      },
    },
  };
</script>
