import ALL_TEMPLATE_COLLECTIONS from '@/graphql/AllTemplateCollections.gql';
import CHANGE_TEMPLATE_COLLECTION_ORDER from '@/graphql/ChangeTemplateCollectionOrder.gql';
import REMOVE_TEMPLATE_COLLECTION from '@/graphql/RemoveTemplateCollection.gql';
import { getBrandedClassString } from '@/components/Elements/Button';

export default {
  data() {
    return {
      templateCollections: [],
    };
  },

  watch: {
    tab(v) {
      if (v === 'collections') {
        this.fetchTemplateCollections();
      }
    },
  },

  methods: {
    async fetchTemplateCollections() {
      const {
        data: { templateCollections },
      } = await this.$apollo.query({
        query: ALL_TEMPLATE_COLLECTIONS,
      });
      this.templateCollections = templateCollections;
    },
    onTemplateCollectionDragChange(params) {
      if (params.moved) {
        const orders = this.templateCollections.map((t, i) => {
          return { id: t._id, order: i };
        });

        this.$apollo.mutate({
          mutation: CHANGE_TEMPLATE_COLLECTION_ORDER,
          variables: {
            orders,
          },
        });
      }
    },
    showTemplateCollectionRemoveDialog(templateCollection) {
      this.$modal.show('dialog', {
        text: this.$t('confirmationDialog'),
        buttons: [
          {
            title: this.$t('yes'),
            class: getBrandedClassString({ primary: true }, 'mr-3'),
            handler: async () => {
              const {
                data: { removeTemplateCollection },
              } = await this.$apollo.mutate({
                mutation: REMOVE_TEMPLATE_COLLECTION,
                variables: { _id: templateCollection._id },
              });

              if (removeTemplateCollection) {
                this.templateCollections = this.templateCollections.filter(
                  (t) => t._id.toString() !== templateCollection._id.toString(),
                );
                this.$modal.hide('dialog');
              }
            },
          },
          {
            title: this.$t('cancel'),
            class: getBrandedClassString({ secondary: true }),
            default: true,
          },
        ],
      });
    },
  },
};
