<template lang="pug">
.campaign-settings-box(@click="activate" :class="boxClasses")
  .d-flex.align-items-center.campaign-settings-box-top(v-if="onSettingsPage")
    transition(name="fade")
      i.fas.fa-arrow-circle-right.campaign-settings-box-undecided-status(
        v-if="campaign && campaign.lastSaved === null"
      )
      template(v-else)
        i.fas.fa-times-circle.campaign-settings-box-error-status(v-if="hasError")
        i.fas.fa-check-circle.campaign-settings-box-warning-status(v-else-if="hasWarning")
        i.fas.fa-check-circle.campaign-settings-box-valid-status(v-else)
    .campaign-settings-box-title
      slot(name="title")
    uil-angle-down.cursor-pointer.campaign-settings-box-settings(:size="'30px'")(v-if="!active")
  .campaign-settings-box-warning(v-if="!active && hasWarning")
    slot(name="warning")
  slide-up-down(v-show="active" :active="active" :duration="500")
    slot(name="content")
    om-button.ml-auto(
      large
      primary
      v-if="!hasError && onSettingsPage"
      @click.stop="close"
      :class="buttonClasses"
    )
      slot(name="button")
    slot(name="afterButton")
</template>

<script>
  import { mapGetters } from 'vuex';
  import { UilAngleDown } from '@iconscout/vue-unicons';

  export default {
    components: { UilAngleDown },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      bottomBorder: {
        type: Boolean,
        default: false,
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      hasWarning: {
        type: Boolean,
        default: false,
      },
      campaign: {
        type: Object,
        default: () => {},
      },
      outlinedButton: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        active: false,
      };
    },

    computed: {
      ...mapGetters(['onboardingFinished']),
      onSettingsPage() {
        return ['campaign_settings', 'use_case_rules'].includes(this.$route.name);
      },
      boxClasses() {
        return {
          'campaign-settings-box-active': this.active,
          'campaign-settings-box-border': !this.active && this.bottomBorder,
          'remove-box-shadow': !this.onSettingsPage,
        };
      },
      buttonClasses() {
        return {
          'brand-btn-primary-reverse': this.outlinedButton,
          'brand-btn-primary-outline': this.outlinedButton,
        };
      },
    },

    watch: {
      show(n) {
        this.active = n;
      },
    },

    created() {
      this.active = this.show;
    },

    methods: {
      activate() {
        this.$emit('activate');
        this.active = true;
      },
      close() {
        this.$emit('close');
        this.active = false;
      },
    },
  };
</script>

<style lang="sass" scoped>
  .remove-box-shadow
    box-shadow: none
</style>
