<template lang="pug">
om-tags(
  :selectedTags="selectedTagsCopy"
  @input="handleInput"
  :items.sync="tags"
  :getRemoveWarningText="getRemoveWarningText"
  @addTag="addTemplateTag"
  @removeTag="removeTemplateTag"
  @changeTag="changeTemplateTag"
)
</template>

<script>
  import { debounce } from 'lodash-es';

  import GET_TEMPLATE_TAGS from '@/graphql/GetTemplateTags.gql';
  import ADD_TEMPLATE_TAG from '@/graphql/AddTemplateTag.gql';
  import REMOVE_TEMPLATE_TAG from '@/graphql/RemoveTemplateTag.gql';
  import CHANGE_TEMPLATE_TAG from '@/graphql/ChangeTemplateTag.gql';
  import GET_TEMPLATE_TAG_USAGE from '@/graphql/GetTemplateTagUsage.gql';

  export default {
    model: {
      prop: 'selectedTags',
      event: 'input',
    },

    props: {
      selectedTags: {
        type: Array,
        default: () => [],
      },
      mapArray: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        tags: [],
        selectedTagsCopy: [],
      };
    },

    watch: {
      selectedTags: {
        handler() {
          this.transformSelectedTagsIfNeeded();
        },
        deep: true,
      },
    },

    async created() {
      await this.fetchTags();
      this.transformSelectedTagsIfNeeded();
    },

    methods: {
      transformSelectedTagsIfNeeded() {
        if (this.mapArray) {
          this.selectedTagsCopy = [];

          for (const selectedTag of this.selectedTags) {
            const foundTag = this.tags.find((t) => selectedTag === t._id);

            if (foundTag) {
              this.selectedTagsCopy.push(foundTag);
            }
          }
        } else {
          this.selectedTagsCopy = this.selectedTags;
        }
      },

      handleInput(event) {
        if (this.mapArray) {
          this.$emit(
            'input',
            event.map((st) => st._id),
          );
        } else {
          this.$emit('input', event);
        }
      },

      async fetchTags() {
        const {
          data: { tags },
        } = await this.$apollo.query({
          query: GET_TEMPLATE_TAGS,
        });

        this.tags = (tags || []).map((t) => ({
          _id: t._id,
          name: t.name.en,
          color: t.color,
        }));
      },
      async addTemplateTag(tag) {
        this.tags.push(tag);

        await this.$apollo.mutate({
          mutation: ADD_TEMPLATE_TAG,
          variables: {
            _id: tag._id,
            name: tag.name,
          },
        });
      },
      async getRemoveWarningText(tag) {
        const {
          data: { count },
        } = await this.$apollo.query({
          query: GET_TEMPLATE_TAG_USAGE,
          variables: {
            _id: tag._id,
          },
        });

        return count
          ? `${this.$t('notifications.tagUsed', { count })} ${this.$t('confirmationDialog')}`
          : this.$t('confirmationDialog');
      },
      async removeTemplateTag(tag) {
        this.tags = this.tags.filter((t) => t._id.toString() !== tag._id.toString());
        await this.$apollo.mutate({
          mutation: REMOVE_TEMPLATE_TAG,
          variables: {
            _id: tag._id,
          },
        });
      },
      changeTemplateTag: debounce(async function ({ tag, overrides }) {
        await this.$apollo.mutate({
          mutation: CHANGE_TEMPLATE_TAG,
          variables: {
            _id: tag._id,
            enName: overrides.name,
          },
        });
      }, 300),
    },
  };
</script>
